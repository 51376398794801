// TODO: Fix this because using env vars here does not work
export const IS_PROD = process.env.PS_BASE_URL === "https://www.polarstern-energie.de";

// Mirrors linkablePageTypes in CMS' config.ts
// Except for customerArea and admin
export type LinkablePageType =
  | "admin"
  | "customerArea"
  | "adminCustomerArea"
  | "customerAreaCalculate"
  | "customerAreaContract"
  | "customerAreaContracts"
  | "faqCategory"
  | "faqOverview"
  | "magazineArticle"
  | "magazineOverview"
  | "magazineTopic"
  | "landingPage"
  | "page"
  | "pressArticle"
  | "pressOverview"
  | "tenantPowerProject"
  | "tenantPowerProjectMeasuringPoint"
  | "tenantPowerProjectMeasuringCreation";

export enum CustomerContractRoute {
  TRANSFER = "uebertragen",
  TERMINATE = "kuendigen",
  MOVE = "umziehen",
  TARIFF_SWITCH = "wechsel",
}

export const customerAreaPrefix = "/kundenbereich";
export const adminAreaPrefix = "/admin";
export const adminPrefix = (customerId: string) =>
  adminAreaPrefix + "/kunden/" + customerId;
export const adminContractRoute = (customerId: string, contractId: string) =>
  adminPrefix(customerId) + "/vertrag/" + contractId;

// List where different page types are mounted at
// TODO: Make sure it's only used with NuxtLinks and no plain <a> tags
const routes: Record<LinkablePageType | string, string> = {
  admin: "/admin/%s",
  adminCustomerArea: adminPrefix(""),
  calculatorPage: "/tarifrechner/",
  contactPage: "/kontakt/",
  customerArea: "/%s",
  customerAreaCalculate: "/bestellen/%s",
  customerAreaContract: "/vertrag/%s",
  customerAreaContracts: "/vertraege/%s",
  faqCategory: "/service/fragen-antworten/%s",
  faqOverview: "/service/fragen-antworten/",
  landingPage: "/",
  magazineArticle: "/magazin/artikel/%s",
  magazineOverview: "/magazin/",
  magazineTopic: "/magazin/themen/%s",
  page: "/%s",
  pressArticle: "/presse/mitteilung/%s",
  pressOverview: "/presse/",
  privacyPage: "/service/datenschutz/",
  recommendationPage: "/weiterempfehlen/",
  tenantPowerRegistrationFallbackForm: "/mieterstrom/formular",
  thgPage: "/elektromobilitaet/thg-quote/",
  tenantPowerProject: "/admin/mieterstrom/kundenanlagen/%s",
  tenantPowerProjectMeasuringPoint: "/admin/mieterstrom/kundenanlagen/%s/messstellen/%s",
  tenantPowerProjectMeasuringCreation:
    "/admin/mieterstrom/kundenanlagen/%s/messstellen/hinzufuegen",
};

const IS_MAINTENANCE_ACTIVE = false;
/**
 * "Soft" maintenance only closes down:
 * - Impact calculator
 * - Tenant Power Signup Form until it's fixed
 */
export const IS_SOFT_MAINTENANCE_ACTIVE = false;

/**
 * Configure whether the calculation of the price cap (Preisbremse)
 * is activated throughout the whole frontend or not.
 */
export const IS_PRICE_CAP_ACTIVE = false;

/**
 * Returns true if maintenance mode is active.
 *  If active, the user will be redirected to config.maintenancePage for all
 * brain api call related pages (customer portal, tariff calc, forms, etc).
 *
 * Can be overwritten locally by setting localStorage.disableMaintenanceMode:
 * localStorage.setItem("disableMaintenanceMode", true)
 *
 * @returns true if maintenance mode is active
 */
export const getIsMaintenanceActive = (
  isMaintenanceModeActivated = IS_MAINTENANCE_ACTIVE,
): boolean => {
  if (
    isMaintenanceModeActivated &&
    typeof window !== "undefined" &&
    localStorage?.getItem("disableMaintenanceMode") === null
  ) {
    return true;
  }

  return false;
};

const config = {
  // If configured, the user will be redirected to this page for all
  // brain api call related pages (customer portal, tariff calc, forms, etc).
  maintenancePage: "/wartungshinweis/",
  // Please keep breakpoints in sync with breakpoints.scss
  // Please prefer CSS over JS
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1260,
    xl: 1440,
    xxl: 1920,
    desktop: 992,
    navigation: 992,
    articleContentWidth: 600,
  },
  // Possible image variants, see <CmsImage> for details. When changing a
  // value here, also change it in asset mirror configuration & generate
  // the new variants
  imageVariants: {
    xxs: 368,
    xs: 576,
    sm: 768,
    desktop: 992,
    maxContentWidth: 1140,
    highRes: 1600,
    maxHighRes: 2500,
  },
  contact: {
    mail: {
      hello: "hallo@polarstern-energie.de",
      sales: "geschaeftskunden@polarstern-energie.de",
      anmeldung: "anmeldung@polarstern-energie.de",
    },
    phone: {
      general: "089 309 042 911",
      tenantPower: "089 309 042 922",
    },
    form: {
      quoteForm: "https://form.asana.com/?k=Mec0lXwD9X5GJhwOC0mxNA&d=4467857416733",
    },
  },
  ekomi: {
    certificateLink: "https://www.ekomi.de/bewertungen-polarstern.html",
    productName: "Wirklich Ökostrom und Wirklich Ökogas von Polarstern",
  },
  // The name of the sanity client that is used to fetch draft data
  sanityDraftDataClientName: "draftDataClient",
  // Functions that can be configured in the CMS as "action targets"
  // See CMS repo clientAction.ts
  interactions: {
    openCookieBox: "Opencookiebox",
    openChat: "Openchat",
  },
  formkit: {
    proId: "fk-63919a15ccd",
  },
  googleAnalytics: {
    gtmId: "GTM-PX7BLN",
  },
  olark: {
    id: "3824-147-10-9401",
  },
  cookieBot: {
    id: "9576782c-0522-4ef7-8e1f-350347a316eb",
  },
  pagination: {
    perPage: 18,
  },
  socials: {
    facebook: {
      url: "https://www.facebook.com/PolarsternEnergie/",
      icon: "sharing/facebook",
      alt: "Facebook",
    },
    instagram: {
      url: "https://www.instagram.com/polarstern_energie/",
      icon: "sharing/instagram",
      alt: "Instagram",
    },
    linkedin: {
      url: "https://www.linkedin.com/company/polarstern-energie/",
      icon: "sharing/linkedin",
      alt: "LinkedIn",
    },
    pinterest: {
      url: "https://www.pinterest.de/polarstern/",
      icon: "sharing/pinterest",
      alt: "Pinterest",
    },
    twitter: {
      url: "https://twitter.com/polarstern_nrgi",
      icon: "sharing/twitter",
      alt: "Twitter",
    },
    xing: {
      url: "https://www.xing.com/pages/polarsterngmbh",
      icon: "sharing/xing",
      alt: "Xing",
    },
    youtube: {
      url: "https://www.youtube.com/user/polarsternenergie",
      icon: "sharing/youtube",
      alt: "YouTube",
    },
    whatsapp: {
      url: "https://wa.me/4917643546221",
      icon: "whatsapp",
      alt: "WhatsApp",
    },
    signal: {
      url: "https://signal.me/#p/+4917643546221",
      icon: "signal",
      alt: "Signal",
    },
  },
  routes,
  swiper: {
    autoChangeDelayShort: 5000,
    autoChangeDelayDefault: 10000,
  },
  campaignCode: { queryParam: "ac" }, // "Aktionscode"
  cooperationCode: { queryParam: "cc" },
  partnerCode: { queryParam: "pc" },
  recommendationValue: 20,
  recommendationCode: {
    slug: "entdecke-polarstern",
    queryParam: "rc",
    codeStorageKey: "recommendation-code",
    isHiddenStorageKey: "recommendation-code-hidden",
  },
  // All the links to static pdf documents like agb, datenschutz, etc.
  // TODO: Move to pages in CMS so that they are easily editable.
  documentLinks: {
    agbGas: "/documents/Polarstern_Oekogas_AGB.pdf",
    agbPower: "/documents/Polarstern_Oekostrom_AGB.pdf",
    agbChargingCards: "/documents/Polarstern_Ladekarten_AGB.pdf",
    datenschutz: "/service/datenschutz",
    widerrufsbelehrungGas:
      "/documents/polarstern_widerrufsbelehrung_widerrufsformular_gas.pdf",
    widerrufsbelehrungPower:
      "/documents/polarstern_widerrufsbelehrung_widerrufsformular_strom.pdf",
    widerrufsbelehrungChargingCards:
      "/documents/Polarstern_Widerrufsbelehrung_Widerrufsformular_Ladekarten.pdf",
  },
  uppr: {
    campaignId: "203",
    // TODO: Needs customization to handle UPPR dev system (runtime IS_PROD variable)
    trackingDomain: "https://trck.polarstern-energie.de/trck",
  },
};

export default config;
